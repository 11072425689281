/* eslint
    func-names: "off"
*/
(function ($) {
  // add markup and events for save credit card optin checkbox and tooltip
  Drupal.behaviors.SaveCreditCard = {
    attach(context, settings) {
      var wrapper = $(
        ".form-type-checkbox.form-item-submitted-payment-information-payment-fields-credit-ocd",
        context
      );
      if (wrapper.length > 0) {
        wrapper.once("save-credit-card", function () {
          var tooltip = wrapper.find("div.description");
          var tooltipOpen = false;
          var infoIcon = $(
            "<button type='button' class='info-icon'>i</button>"
          ).appendTo(wrapper);
          var closeButtonMarkup = '<button type="button" class="close-button">';
          closeButtonMarkup +=
            '<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">';
          closeButtonMarkup +=
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M6.62132 8.34924L11 12.7279L13.1213 10.6066L8.74264 6.22792L12.7279 2.24264L10.6066 0.121321L6.62132 4.1066L2.51472 0L0.393403 2.12132L4.5 6.22792L0 10.7279L2.12132 12.8492L6.62132 8.34924Z" fill="white"/>';
          closeButtonMarkup += "</svg></button>";
          var closeButton = $(closeButtonMarkup).appendTo(tooltip);

          function closeTooltip() {
            tooltipOpen = false;
            tooltip.removeClass("open");
            $("#container").off("click");
          }
          function openTooltip(e) {
            e.stopPropagation();
            tooltipOpen = true;
            tooltip.addClass("open");
            $("#container").on("click", closeTooltip);
          }

          infoIcon.click(function (e) {
            if (tooltipOpen) {
              closeTooltip();
            } else {
              openTooltip(e);
            }
          });
          closeButton.click(closeTooltip);
          tooltip.click(function (e) {
            e.stopPropagation();
          });
        });
      }
    },
  };
})(jQuery);
