/* eslint
    func-names: "off"
*/
(function ($) {
  // add markup and events for displaying amount-specific explainer text
  Drupal.behaviors.AmountText = {
    attach(context, settings) {
      const redistrictingText = {
        oneTime: [
          "Fund this once-in-a-decade fight for fair maps with $10.",
          "Make sure we have fair maps before the 2022 midterms; give $20.22 to keep our legal, advocacy and organizing teams going nationwide.",
          "Support the tireless work all 54 of our local chapters are doing across the country to ensure districts are drawn fairly.",
        ],
        monthly: [
          "Fund this once-in-a-decade fight for fair maps with $10/month.",
          "Make sure we have fair maps before the 2022 midterms; give $20.22/month to keep our legal, advocacy and organizing teams going nationwide.",
          "Support the tireless work all 54 of our local chapters are doing across the country to ensure districts are drawn fairly by giving $54/month.",
        ],
      };

      const eoy2021Text_Explainer = {
        oneTime: [
          "Help us defend civil liberties across all 50 states, Puerto Rico, and D.C.",
          "Celebrate over 100 years of ACLU’s defense of civil liberties — and fuel the fight for the next century.",
          "We have 288 full-time attorneys on staff fighting on behalf of thousands of plaintiffs and protecting civil liberties. Support their incredible work today.",
        ],
        monthly: [
          "Fund our once-in-a-decade fight for fair maps.",
          "Give $20.22 to keep our legal, advocacy and organizing teams going nationwide in preparation for the 2022 midterms.",
          "We've won 66 major cases this year protecting voting rights, transgender rights, immigration rights, and more. Help us keep winning!",
        ],
      };

      const golInviteMAB = {
        oneTime: [
          "Fund our once-in-a-decade fight for fair maps.",
          "Give $20.22 to keep our legal, advocacy and organizing teams going nationwide in preparation for the 2022 midterms.",
          "We've won 66 major cases this year protecting voting rights, transgender rights, immigration rights, and more. Help us keep winning!",
        ],
        monthly: [],
      };

      const eoy2021Text_SingleAmout = {
        oneTime: [
          "ACLU supporters are, on average, donating this amount this season.",
        ],
        monthly: [
          "ACLU supporters are, on average, donating this amount this season.",
        ],
      };

      // configure this feature:
      // these are node IDs of forms that need to have amount texts added to them
      const amountTextByNodeId = {
        68865: redistrictingText, // staging iframe form
        68871: redistrictingText, // staging main donate form
        70606: redistrictingText, // prod iframe form
        70609: redistrictingText, // prod main donate form

        // 69138: redistrictingText, // test form
        // 69132: eoy2021Text, // staging main donate form
        71362: eoy2021Text_Explainer, // prod explainer donate form
        71350: eoy2021Text_SingleAmout, // prod single amount donate form

        // 69181: golInviteMAB, // test form
        71577: golInviteMAB,
      };

      function buildTextMarkup(texts) {
        const markup = $("<div class='donation-amount-text-wrapper'>");
        const markupInner = texts.map(function (text, num) {
          return (
            "<div class='donation-amount-text' data-order=" +
            num +
            ">" +
            text +
            "</div>"
          );
        });
        return markup.append(markupInner.join(""));
      }

      function activateText(texts, parentSelector, button) {
        // hide all texts, even if the click was on "other" button
        $(parentSelector).find(".donation-amount-text").removeClass("active");
        $(parentSelector + " .form-type-radio.active").removeClass("active");
        const buttonFor = $(button).attr("for");
        let buttonNumber = null;
        if (buttonFor.length > 0) {
          buttonNumber = parseInt(buttonFor[buttonFor.length - 1], 10);
        }
        // only show a text if the number matches a text in the config object
        if (buttonNumber !== null && texts[buttonNumber - 1]) {
          $('#' + buttonFor).parent().addClass("active");
          $(parentSelector)
            .find(".donation-amount-text:nth-child(" + buttonNumber + ")")
            .addClass("active");
        }
      }

      const nodeIds = Object.keys(amountTextByNodeId);
      for (let i = 0; i < nodeIds.length; i++) {
        const nodeId = nodeIds[i];
        const addAmountText = $("body.page-node-" + nodeId, context).length > 0;
        // are we on a form that needs these added?
        if (addAmountText) {
          $("form.fundraiser-donation-form").addClass(
            "show-donation-amount-texts"
          );
          const oneTimeMarkup = buildTextMarkup(
            amountTextByNodeId[nodeId].oneTime
          );
          const monthlyMarkup = buildTextMarkup(
            amountTextByNodeId[nodeId].monthly
          );

          $("#webform-component-donation--amount").append(oneTimeMarkup);
          $("#webform-component-donation--recurring-amount").append(
            monthlyMarkup
          );

          // show the corresponding text when donation amounts are clicked
          $("#edit-submitted-donation-amount label.option").click(function () {
            activateText(
              amountTextByNodeId[nodeId].oneTime,
              "#webform-component-donation--amount",
              this
            );
          });
          if (jQuery("#edit-submitted-donation-amount input:checked").parent().find('label.option').length) {
            activateText(
              amountTextByNodeId[nodeId].oneTime,
              "#webform-component-donation--amount",
              jQuery("#edit-submitted-donation-amount input:checked").parent().find('label.option')
            );
          }

          $("#edit-submitted-donation-recurring-amount label.option").click(
            function () {
              activateText(
                amountTextByNodeId[nodeId].monthly,
                "#webform-component-donation--recurring-amount",
                this
              );
            }
          );
          if (jQuery("#edit-submitted-donation-recurring-amount input:checked").parent().find('label.option').length) {
            activateText(
              amountTextByNodeId[nodeId].monthly,
              "#webform-component-donation--recurring-amount",
              jQuery("#edit-submitted-donation-recurring-amount input:checked").parent().find('label.option')
            );
          }
        }
      }
    },
  };
})(jQuery);
