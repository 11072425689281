/*
 * Contains helper variables and functions for recurring springboard
 * calculations and constants.
 *
 * Before adding new things to this file, be sure to check that
 * Drupal.settings doesn't already have what you need. You can see
 * what's in it by typing `Drupal.settings` in the devtools console.
 * That object has sub-objects that contain a lot of useful values.
 */
/**
 * Donation Types: one-time, monthly, annual
 * Donation Frequency: NO_RECURR, recurs, sb_fs_annually
 * Donation Form Types: switcher, one-time only, monthly only, annual only 
 * Donation Form Wrapper Layouts: singlestep, multistep
 * Note: 
 *  The donation form DOM changes depending on what donation frequencies are enabled. 
 *  If one-time, monthly and annual are enabledd (aka switcher) the classes and ids available are different.
 *  In some cases this doesn't matter in other cases it does.
 *  There is a spin-off ticket to have the helpers match these expectations of different form types DOM elements
 *  available for the developer. Improve self-documentation of the variable names.
 */
(function ($) {
  const DONATION_RECURRING_QUERY = "input[type='radio'][name='submitted[donation][recurs_monthly]']"; // the donation frequency radio buttons: one-time, monthly or annual
  const DONATION_FREQUENCY_QUERY = 'input[type="radio"][name="submitted[donation][recurs_monthly]"]:checked';
  const PAYMENT_TYPES_QUERY = ".form-item-submitted-extra-fields-payment-options, input.apple-pay-button";
  const DONATION_TYPE_QUERY = "#webform-component-donation--recurs-monthly"

  function getOneTimeDonationRadioButton(){
    return $('input[type="radio"][name="submitted[donation][recurs_monthly]"][value="NO_RECURR"]');
  }

  function getMonthlyDonationRadioButton(){
    return $('input[type="radio"][name="submitted[donation][recurs_monthly]"][value="recurs"]');
  }

  function getAnnualDonationRadioButton() {
    return $('input[type="radio"][name="submitted[donation][recurs_monthly]"][value="sb_fs_annually"]');
  }

  function isOneTimeDonationRadioButtonSelected(){
    return getOneTimeDonationRadioButton().is(':checked');
  }

  function isAnnualDonationRadioButtonSelected(){
    return getAnnualDonationRadioButton().is(':checked');
  }

  function isMonthlyDonationRadioButtonSelected(){
    return getMonthlyDonationRadioButton().is(':checked');
  }

  function isOneTimeOtherRadioSelected() {
    return $('input:radio[name="submitted[donation][amount]"]:checked').val() == 'other';
  }

  function isMonthlyOtherRadioSelected() {
    return $('input:radio[name="submitted[donation][recurring_amount]"]:checked').val() == 'other';
  }

  function isAnnualOtherRadioSelected() {
    return $('input:radio[name="frequencies[sb_fs_annually_amount]"]:checked').val() == 'other';
  }

  function getFrequencyRadioButtons() {
    return $(DONATION_TYPE_QUERY);
  }

  function selectOneTimeDonationRadioButton() {
    return getOneTimeDonationRadioButton().click()
  }

  function selectAnnualDonationRadioButton() {
    return getAnnualDonationRadioButton().click()
  }

  /* Appears to only support switcher forms. Should support all form types. */
  function getDonationFrequency() {
    return $(
      DONATION_FREQUENCY_QUERY
    ).val()
  }

  function getReadableDonationFrequency() {
    const readableMap = {
      'sb_fs_annually': 'annual',
      'recurs': 'monthly',
      'NO_RECURR': 'one-time',
    }

    let frequency = ''
    if ($(DONATION_FREQUENCY_QUERY).length) {
      frequency = $(DONATION_FREQUENCY_QUERY).val()
    } else {
      frequency = $('input[name="submitted[donation][recurs_monthly]"]').val()
    }
    
    return readableMap[frequency]
  }

  module.exports = {
    ONE_TIME_FREQUENCY: "NO_RECURR",
    MONTHLY_FREQUENCY: "recurs",
    ANNUAL_FREQUENCY: "sb_fs_annually",
    DONATION_RECURRING_QUERY,
    DONATION_FREQUENCY_QUERY,
    PAYMENT_TYPES_QUERY,
    DONATION_TYPE_QUERY,
    getOneTimeDonationRadioButton,
    getMonthlyDonationRadioButton,
    getAnnualDonationRadioButton,
    isOneTimeDonationRadioButtonSelected,
    isMonthlyDonationRadioButtonSelected,
    isAnnualDonationRadioButtonSelected,
    isMonthlyOtherRadioSelected,
    isAnnualOtherRadioSelected,
    isOneTimeOtherRadioSelected,
    getFrequencyRadioButtons,
    selectOneTimeDonationRadioButton,
    selectAnnualDonationRadioButton,
    getDonationFrequency,
    getReadableDonationFrequency,
  };
})(jQuery);
