/* eslint
    prettier/prettier: "off",
    eqeqeq: "off",
    operator-linebreak: "off",
    func-names: "off",
    camelcase: "off",
    no-use-before-define: "off",
    max-nested-callbacks: "off",
    one-var: "off",
    no-new-wrappers: "off",
    no-cond-assign: "off",
    no-constant-condition: "off",
    no-shadow: "off",
    prefer-destructuring: "off",
    no-unused-vars: "off",
    no-inner-declarations: "off",
    no-undef: "off",
    no-array-constructor: "off",
    no-redeclare: "off",
    block-scoped-var: "off",
    object-shorthand: "off",
    no-useless-concat: "off"
*/
(function($) {
  const { getFrequencyRadioButtons, getDonationFrequency, isOneTimeDonationRadioButtonSelected, isMonthlyDonationRadioButtonSelected, isAnnualDonationRadioButtonSelected, isMonthlyOtherRadioSelected, isAnnualOtherRadioSelected, isOneTimeOtherRadioSelected, ONE_TIME_FREQUENCY, MONTHLY_FREQUENCY, ANNUAL_FREQUENCY, DONATION_FREQUENCY_QUERY, DONATION_TYPE_QUERY } = require('./utils/donation-form-helper.js')

  Drupal.behaviors.DonationFormJS = {
    attach: function (context, settings) {

    /* Custom donation form functions for mobile */
    function showForm(formSelectorID) {
      // The extra-fields fieldset contains paypal/switcher specific fields that should only be toggled via a click event
      $(formSelectorID).find("fieldset:not(:first,'#webform-component-extra-fields')").show();
      $(formSelectorID).find("#edit-actions").show();
      $(formSelectorID).find("#edit-submit").show();
      $(formSelectorID).find('.donation-processing-wrapper').hide().css('display','none');
    }

    function hideForm(formSelectorID) {
      $(formSelectorID).find('#webform-component-donor-information').hide();
      $(formSelectorID).find('#webform-component-billing-information').hide();
      $(formSelectorID).find('#webform-component-payment-information').hide();
      $(formSelectorID).find("#edit-actions").hide();
    }

    function hideEmbedForm(formSelectorID) {
      $(formSelectorID).find(".form-required-info").hide();
      $(formSelectorID).find("fieldset:not(:first)").hide();
      $(formSelectorID).find(".signup-wrapper").show();
      $(".embed-form #overlay-bottom").insertBefore($(formSelectorID).find("#edit-actions"));
      $(formSelectorID).find("#edit-actions").hide();
    }

   // Use to validate paypal submits triggered by Jquery not by a user click.
   Drupal.behaviors.DonationFormJS.acluSubmitPaypal = function(event) {
     // Make sure this is only run once so not seen as spammy by mobile browsers

     // Programmitcally submit paypal payment - by default a manual process in system
     // which the timeout is supposed to help simulate.
     // Add timeout to create a delay as a user would between selecting
     // the paypal payment method and submitting the form so paypal related
     // JS has time to load delay is also set at 500 for mobile safari
     setTimeout(function(){
       try {
         Drupal.braintreeInstance.submitPaypalFields(document.createEvent('Event'));
       } catch(err) {
         // Some browsers work better with a longer timeout so try again but wait longer
         setTimeout(function(){Drupal.braintreeInstance.submitPaypalFields(document.createEvent('Event'));},1000);
       }
     }, 500);
   }

    $.fn.isAfter = function(sel){
      return this.prevAll().filter(sel).length !== 0;
    };

    $.fn.isBefore= function(sel){
      return this.nextAll().filter(sel).length !== 0;
    };

    $.event.special.destroyed = {
      remove: function(o) {
        if (o.handler) {
          o.handler()
        }
      }
    }

    function popupHelp(url) {
      window.open(url,"ACLU Help","width=800,height=500,location=no,directories=no,status=no,menuBar=no,scrollBars=yes,resizable=yes");
    }

    var waitForFinalEvent = (function() {
      var timers = {};
      return function(callback, ms, uniqueId) {
        if (!uniqueId) {
          uniqueId = "Don't call this twice without a uniqueId";
        }
        if (timers[uniqueId]) {
          clearTimeout(timers[uniqueId]);
        }
        timers[uniqueId] = setTimeout(callback, ms);
      };
    });

    $(document).ready(function() {

      // If GCLID is in the query string, set the hidden form component to its value.
      $('input[name="submitted[google_click_id]"]').once('populate-google-click-id-field', function() {
        var gclid = Drupal.behaviors.FormJS.getQueryParamValue('gclid', document.location.search);
        if (gclid) {
          $('input[name="submitted[google_click_id]"]').val(gclid);
        }
      });

      /**
       * jQuery to support fundraiser behaviors.
       */

      $(".node form, .node-type-p2p-donation-form form.fundraiser-donation-form").each(function() {
        var form_id = $(this).attr('id');
        var formSelectorID = "#" + form_id + " ";

        if ($.browser.mozilla && ($.browser.version.substr(0, 3) == '1.9' || $.browser.version.substr(0, 3) == '1.8')) {
          // do nothing
        } else {
          $(formSelectorID + '#edit-submitted-payment-information-payment-fields-credit').wrapAll('<div class="credit-card-info-wrapper"><div class="cc-info-holder"></div></div>');
        }
      });

      // Configure the Spinner for donation forms
      $('.donation-processing-wrapper').initialize(function(){
        $(this).html('<p class="processing-thank-you">Thank you.</p><p class="processing-text">Your donation is being processed.</p>' + '<div class="processing-spinner"></div>');
        var opts = {
          lines : 13, // The number of lines to draw
          length : 3, // The length of each line
          width : 2, // The line thickness
          radius : 3, // The radius of the inner circle
          corners : 0.5, // Corner roundness (0..1)
          rotate : 0, // The rotation offset
          direction : 1, // 1: clockwise, -1: counterclockwise
          color : '#000', // #rgb or #rrggbb or array of colors
          speed : 1, // Rounds per second
          trail : 25, // Afterglow percentage
          shadow : false, // Whether to render a shadow
          hwaccel : false, // Whether to use hardware acceleration
          className : 'spinner', // The CSS class to assign to the spinner
          zIndex : 2e9, // The z-index (defaults to 2000000000)
          top : '50%', // Top position relative to parent
          left : '50%' // Left position relative to parent
        };
        var target = $(this);
        const Spinner = require('./vendor/spin.js');
        var spinner = new Spinner(opts).spin(target);
      });

      // When the back button is pressed reset form. For Paypal payments.
      $('.webform-client-form').submit(function(){
        $(window).unload(function () {
          $('.webform-client-form').trigger("reset");
        });
      });

      // Make sure cc number and cvv are numeric
      $('#edit-submitted-credit-card-information-card-number').numeric();
      $('#edit-submitted-credit-card-information-card-cvv').numeric();
      $('#edit-submitted-credit-card-information-card-expiration-date-day').hide();

      // When the amount changes, change the displayed total.
      $('#edit-submitted-donation-quantity').change(function() {
        $('#total').empty();
        var total = this.value * $('#webform-component-donation--amount input:radio:checked').val();
        $('#edit-submitted-donation-quantity').after('<p id="total">Total: $' + total + '</p>');
      });

      // When other amount changes, make sure the other amount radio is correctly set.
      $('.singlestep-donation input#edit-submitted-donation-other-amount').change(function() {
        if ($(this).val() != '') {
          $('input:radio[name="submitted[donation][amount]"][value="other"]').attr('checked', true)
        }
      });
      // Iterate through billing amounts, comma formatting each value for gift amounts as radio buttons (excluding other field)
      $('input[name*=amount][type=radio]').each(function() {
        var value = $(this).val();
        if (typeof(value) !== 'undefined' && $.isNumeric(value)) {
          id = $(this).prop('id');
          if (typeof(id) !== 'undefined' && id.length > 0) {
            if (typeof(Drupal.settings.fundraiser) !== "undefined" && typeof(Drupal.settings.fundraiser.currency) !== "undefined" && typeof(Drupal.settings.fundraiser.currency.symbol) !== "undefined") {
              var symbol = Drupal.settings.fundraiser.currency.symbol;
            }
            else {
              var symbol = "$";
            }
            $('label[for=' + id +']').text(symbol + parseFloat(value).toLocaleString("en"));
          }
        }
      });

      // For switcher forms we have to check for one time and recurring other amount fields
      $('.node-type-donation-form .default-form-wrapper #webform-component-donation--other-amount').addClass('other-amount-wrapper');
      $('.node-type-p2p-donation-form .default-form-wrapper #webform-component-donation--other-amount').addClass('other-amount-wrapper');
      $('.node-type-donation-form .default-form-wrapper #webform-component-donation--recurring-other-amount').addClass('recurring-other-amount-wrapper');
      $('.node-type-p2p-donation-form .default-form-wrapper #webform-component-donation--recurring-other-amount').addClass('recurring-other-amount-wrapper');

      // Add sample check image
      if ($('.account-accNum').length > 0 && $('.sample-check').length == 0){
        var sampleCheckAlt = "Illustration of a sample check showing routing number at the left bottom corner and account number at the bottom center.";
        var sampleCheckImage = "<div class='sample-check'><p class='sample-check__title form-required-info'>Sample check</p><img src='https://static.aclu.org/images/sample-check-retina.png' alt='" + sampleCheckAlt + "' /></div>";
        $('.account-accNum').after(sampleCheckImage);
      }
    });
    $(window).load(function() {
      var optionClass = new Array();
      var paypalChecked;
      var paypalAvailable;
      var recurringClicked;
      var optionClass = new Array();
      var win = $(this);
      var isMultistep = $('.multistep-donation').length;
      const picOffline = $('form.offline.fundraiser-donation-form').length
      var showOtherField = (isMultistep || picOffline) ? "block" : "inline-flex";

      // Display a warning message to admins if paypal is misconfigured
      if ($('.logged-in').length) {
        var errorMsg = "<div class='aclu error'>Paypal is misconfigured. Consult the documentation for proper setup. Contact web_tech@aclu.org if you need assistance.</div>";
        if ($('#paypal-container').length > 0) {
          // the paypal payment method is checked are componenets in the form?
          if ($("#webform-component-extra-fields--payment-options input[type='radio'][value='1']").length > 0) {
            $("#edit-submitted-extra-fields-payment-options .form-type-radio:last-of-type").addClass('good-config');
          } else {
            $(".message-wrapper").addClass('missing-payment-config1');
            $(".message-wrapper").prepend(errorMsg);
          }
          if (typeof Drupal.behaviors.braintreePaypal == 'undefined') {
            $(".message-wrapper").addClass('missing-bt-functions');
            // compact flow checkbox is not checked
            $(".message-wrapper").prepend(errorMsg);
          }
        } else if ($("#webform-component-extra-fields--payment-options input[type='radio'][value='1']").length > 0) {
          // paypal component fields have been added but paypal payment method not checked
          $(".message-wrapper").addClass('missing-payment-config2');
          $(".message-wrapper").prepend(errorMsg);
        }
      }

      // Donation form custom field processing
      /* secondary advocacy form body text processing */
      if ($('.node-type-donation-form .action-donation-form .custom-title').length > 0) {
        $('.node-type-donation-form .action-donation-form .custom-title').insertBefore('#secondary-advo-title');
      }
      if ($('.node-type-donation-form .action-donation-form #webform-component-c4-disclaimer').length > 0) {
        $('.node-type-donation-form .action-donation-form #webform-component-c4-disclaimer').appendTo('.field-name-body');
      }
      if ($('.node-type-donation-form .action-donation-form #webform-component-c3-disclaimer').length > 0) {
        $('.node-type-donation-form .action-donation-form #webform-component-c3-disclaimer').appendTo('.field-name-body');
      }
      if ($('.node-type-donation-form .action-donation-form .aclu-donate-share-simple').length > 0) {
        $('.node-type-donation-form .action-donation-form .aclu-donate-share-simple').insertAfter('.field-name-body');
      }
      if ($('.not-logged-in').length  && $('.node-type-donation-form .action-donation-form').length) {
        $('.alert.status').addClass('not-logged-in');
      }
      /* Default donation subtitle, right-column */
      if ($(".node-type-donation-form .default-form-wrapper #content .page-title").length > 0) {
        if ($(".default-form-wrapper #content .field-name-field-aclu-subtitle").length > 0) {
          $(".default-form-wrapper #content .field-name-field-aclu-subtitle").wrap("<div class='page-subtitle'><h2></h2></div>");
          $(".default-form-wrapper #content .page-subtitle").insertAfter(".page-title");
        }
      }
      if ($(".node-type-donation-form .default-form-wrapper #content .field-name-field-aclu-right-column").length > 0) {
        $(".default-form-wrapper #content .field-name-field-aclu-right-column").prependTo('.field-name-body');
        $(".field-name-body").addClass("right-content");
      }
      /* leadership donation forms use webform-component to display a banner above the body text */
      if ($(".node-type-donation-form #webform-component-form-body-header").length > 0) {
        $(".node-type-donation-form #webform-component-form-body-header").prependTo(".field-name-body");
        $(".field-name-body").addClass("body-header-content");
      }

      /* Add extra classes if this is a canvass donation form */
      curPath = window.location.pathname;
      if (curPath.toLowerCase().indexOf("canvass") >= 0) {
        $('body').addClass('canvass');
        if (curPath.toLowerCase().indexOf("one-time") >= 0) {
          $('body').addClass('one-time');
        } else if (curPath.toLowerCase().indexOf("monthly") >= 0) {
          $('body').addClass('monthly');
        }
      }

      /* Add custom class to forms with no radio button amounts. They use the an amount textfield for user input instead. */
      if ($('#webform-component-donation--amount #edit-submitted-donation-amount.form-text').length && $('#webform-component-donation--recurring-amount.control-group.webform-component-hidden').length) {
        $('#edit-submitted-donation-amount.form-text').addClass("text-amount");
        $('.field-prefix').next().andSelf().wrapAll("<div class='amount-text-field-wrapper'></div>");
      }

      // General donation form overrides for styling
      $(".default-form-wrapper #content form").once('styled', function() {
        $(".default-form-wrapper #content form").each(function(index, formContext) {

          var form_id = $(this).attr('id');
          var formSelectorID = "#" + form_id + " ";
          optionClass[form_id] = null;

          if ($('#webform-component-payment-information--payment-method input[type="radio"]:checked').val() === 'paypal') {
            paypalChecked = true;
          } else {
            paypalChecked = false;
          }

          if ($('#webform-component-payment-information--payment-method input[value="paypal"]').length > 0) {
            paypalAvailable = true;
          } else {
            paypalAvailable = false;
          }
          if ($(DONATION_TYPE_QUERY + ' input[type="radio"]:checked').val() === MONTHLY_FREQUENCY || $(DONATION_TYPE_QUERY + ' input[type="radio"]:checked').val() === ANNUAL_FREQUENCY) {
            recurringClicked = true;
          } else {
            recurringClicked = false;
          }
          optionClass[form_id] = null;
          if (recurringClicked) {
            $('#webform-component-payment-information--payment-method').hide();
          }
          if ($(formSelectorID + '.form-item-submitted-payment-information-payment-fields-credit-card-cvv').length > 0) {
            $(formSelectorID + '.form-item-submitted-payment-information-payment-fields-credit-card-cvv').addClass('security-code-wrapper');
          }

          // Add classes and helper text
          var infoSignupFirstLabel = $(formSelectorID + "div").filter(function() {
            return ((" " + $(this).attr('id') + " ").match(/webform-component-payment-information-.*-may-we-share-your-info/) != null);
          });
          infoSignupFirstLabel.find('label:first').addClass('custom-width');

          // Make donation form checkbox text wrap consistently
          var infoSignup = $(formSelectorID + "label").filter(function() {
            return ((" " + $(this).attr('for') + " ").match(/edit-submitted-payment-information-.*-may-we-share-your-info-\d*/) != null);
          });
          infoSignup.addClass('your-info-label');

          if ($(formSelectorID + '#webform-component-payment-information--profile-may-we-share-your-info .description p').length) {
            var description_html = ' ' + $(formSelectorID + '#webform-component-payment-information--profile-may-we-share-your-info .description p').html();
            $(formSelectorID + '#webform-component-payment-information--profile-may-we-share-your-info .description').remove();
            $(formSelectorID + '.your-info-label .help-text').remove();
            $(formSelectorID + '#webform-component-payment-information--profile-may-we-share-your-info .your-info-label').append(description_html);
          }
          $(formSelectorID + '#edit-actions').next('.description').addClass('submit-description');
          $(formSelectorID + "label:contains('Expiration Date')").addClass("other-input-label");
          $(formSelectorID).attr("novalidate", "novalidate");

          $('.default-form-wrapper ' + formSelectorID + '#webform-component-donation').addClass('donation-buttons');

          $(".default-form-wrapper " + formSelectorID + DONATION_TYPE_QUERY + " label:contains('Type')").addClass("donation-label");
          $('.default-form-wrapper .singlestep-donation' + formSelectorID + '#webform-component-donation--amount').attr("tabindex", 0);

          $(".node-type-donation-form .default-form-wrapper " + formSelectorID + "#webform-component-donation #webform-component-donation--other-amount .field-prefix, .node-type-donation-form .default-form-wrapper " + formSelectorID + "#webform-component-donation #webform-component-donation--recurring-other-amount .field-prefix").wrap("<div class='prefix-span-wrapper'></div>");
          $(".node-type-p2p-donation-form .default-form-wrapper " + formSelectorID + "#webform-component-donation #webform-component-donation--other-amount .field-prefix, .node-type-p2p-donation-form .default-form-wrapper " + formSelectorID + "#webform-component-donation #webform-component-donation--recurring-other-amount .field-prefix").wrap("<div class='prefix-span-wrapper'></div>");
          $('.default-form-wrapper ' + formSelectorID + '#webform-component-donation--amount .form-radios').addClass('clearfix');
          $('.node-type-donation-form .default-form-wrapper ' + formSelectorID + '#webform-component-donation #webform-component-donation--other-amount .form-text, .node-type-donation-form .default-form-wrapper ' + formSelectorID + '#webform-component-donation #webform-component-donation--recurring-other-amount .form-text').wrap("<span class='currency-in-input'></span>");
          $('.node-type-p2p-donation-form .default-form-wrapper ' + formSelectorID + '#webform-component-donation #webform-component-donation--other-amount .form-text, .node-type-p2p-donation-form .default-form-wrapper ' + formSelectorID + '#webform-component-donation #webform-component-donation--recurring-other-amount .form-text').wrap("<span class='currency-in-input'></span>");
          $('.default-form-wrapper ' + formSelectorID + '#webform-component-donation #webform-component-donation--other-amount .currency-in-input, .default-form-wrapper ' + formSelectorID + '#webform-component-donation #webform-component-donation--recurring-other-amount .currency-in-input').prepend("<span class='symbol'>$</span>");
          $('.node-type-donation-form .default-form-wrapper ' + formSelectorID + '#webform-component-donation .prefix-span-wrapper').prepend("<span class='prefix-text'>Enter Donation Amount</span>");
          $('.node-type-p2p-donation-form .default-form-wrapper ' + formSelectorID + '#webform-component-donation .prefix-span-wrapper').prepend("<span class='prefix-text'>Enter Donation Amount</span>");
          
          // Donation Frequency label wrappers includes apple pay styling classes
          $('.default-form-wrapper ' + formSelectorID + ' #edit-submitted-donation-recurs-monthly label').each(function() {
            if ($(this).css('display') === 'none') {
              $(this).parent().css('display', 'none');
              // If the parent is hidden no need to hide the label since
              // Springboard doesn't reset it properly upon form error
              $(this).css('display', '');
            }
            // spin-off ticket to explore recurring-simple class this line is not appying the span to the label. 
            $(this).children().not('input').wrapInner("<span class='button-label recurring-simple' rel='" + $(this).text() + "'><span>");
            if ($(this).parent().children('input').val() == "recurs") {
              $(this).parents("div").eq(0).addClass("monthly-wrapper");
              $('.default-form-wrapper ' + formSelectorID + '#webform-component-donation #recurring-message').addClass("show-msg");
            }
            if ($(this).parent().children('input').val() == "sb_fs_annually") {
              $(this).parents("div").eq(0).addClass("annual-wrapper");
            }
          });

          $(formSelectorID + "label:contains('CVV')").addClass("other-input-label");
          $(".default-form-wrapper " + formSelectorID + '.form-item-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-month .custom-select-container').addClass('auto-size');
          $(".default-form-wrapper " + formSelectorID + '.form-item-submitted-payment-information-payment-fields-credit-expiration-date-card-expiration-month .select').addClass('auto-size');
          $('.default-form-wrapper ' + formSelectorID + '#webform-component-donation--amount .form-radios').addClass('clearfix');

          $(formSelectorID + 'input[type="text"]').each(function() {

            var inputLabel = $(formSelectorID + "label[for='" + $(this).attr('id') + "']");
            var labelText = inputLabel.text().toLowerCase();

            if ($(this).attr('id').match(/edit-submitted-donation-other-amount*/)) {
              inputLabel.addClass('hidden-element');
            }

            inputLabel.addClass('text-input-label');

            if ($('.node-type-donation-form .default-form-wrapper, .node-type-p2p-donation-form .default-form-wrapper').length) {
              // bring the number keyboard up on mobile/tablet for applicable number fields on donation forms
              if ($(this).attr('id') == "edit-submitted-payment-information-payment-fields-credit-card-number" || $(this).attr('id') == "edit-submitted-donor-information-phone-number" || $(this).attr('id') == "edit-submitted-payment-information-payment-fields-credit-card-cvv") {
                var numberInput = document.getElementById($(this).attr('id'));
                numberInput.addEventListener("touchstart", Drupal.behaviors.FormJS.touchStart, false);
                numberInput.addEventListener("keydown blur", Drupal.behaviors.FormJS.touchType, false);
              }
            }
          });

          $(formSelectorID + '.webform-component-fieldset legend').each(function() {
            if (!$(this).hasClass("collapse-processed")) {
              if ($(this).html().toLowerCase().indexOf("(optional)") >= 0) {
                var wrapOptional = $(this).html().replace(/\(optional\)/i, "<span class='legend-paren-text'>(optional)</span>");
                $(this).html(wrapOptional);
              }
            }
          });

          // Add aria labels for better accessibility for screen readers
          $(formSelectorID + 'input.required').each(function() {
            $(this).attr('aria-required','true');
            $(this).attr('data-required','true');
          });
          $(formSelectorID + '.webform-component-textfield input').not('.required').each(function() {
            $(this).attr('aria-required','false');
            $(this).attr('data-required','false');
            $(this).siblings('label').append('(optional)');
          });

          // The required class is not in a good place on radio buttons apply a custom solution for amounts
          $(formSelectorID + '.webform-component-radios.control-group').add(formSelectorID + '.form-item.form-type-radios.control-group').each(function() {
            $(this).attr('role','radiogroup');
            $(this).attr('aria-required','true');
            $(this).find('label:first').attr('data-required','true');
            if (isMultistep && ($(this).attr('id') == 'webform-component-donation--recurring-amount' || $(this).attr('id') == 'webform-component-donation--amount' || $(this).hasClass('form-item-frequencies-sb-fs-annually-amount'))) {
              $(this).find('.form-type-radio').attr('role', 'button').attr('tabindex', 0);
            } 
          });

          // /////////////////////////////////
          // For custom one-time other label
          var otherID = $('#webform-component-donation--amount').find('input[type="radio"][value="other"]').attr('id');
          var otherLabelVal = $('#' + otherID).parents("div").find("label[for=" + otherID + "]").addClass('other-label-amount').text();
          var monthlyOtherLabelVal = $('[name="submitted[donation][recurring_amount]"][value="other"]').parent("div").find("label").addClass('recur-other-label-amount').text();
          var annualOtherLabelVal = $('[name="frequencies[sb_fs_annually_amount]"][value="other"]').parent("div").find("label").addClass('recur-other-label-amount').text();
          if (otherLabelVal.toLowerCase().trim() !== 'other') {
            $('.other-label-amount').addClass('custom-label');
          }
          if (monthlyOtherLabelVal.toLowerCase().trim() !== 'other') {
            $('.recur-other-label-amount').addClass('custom-label');
          }
          if (annualOtherLabelVal.toLowerCase().trim() !== 'other') {
            $('.recur-other-label-amount').addClass('custom-label');
          }
          if ($(formSelectorID + '#webform-component-donation .other-amount-wrapper, '+formSelectorID + '#webform-component-donation .recurring-other-amount-wrapper').length) {
            // support number keyboard for mobile (annual and monthly share the same recurring_other_amount input field)
            $('submitted[donation][other_amount]').add('submitted[donation][recurring_other_amount]').on("touchstart", Drupal.behaviors.FormJS.touchStart, false);
            $('submitted[donation][other_amount]').add('submitted[donation][recurring_other_amount]').on("keydown blur", Drupal.behaviors.FormJS.touchType, false);
            $(formSelectorID + '#webform-component-donation .other-amount-wrapper .form-text, '+formSelectorID + '#webform-component-donation .recurring-other-amount-wrapper .form-text').attr('inputmode', "decimal");
            $('#edit-submitted-payment-information-payment-fields-credit-card-cvv, #edit-submitted-payment-information-payment-fields-credit-card-number').attr('inputmode', 'numeric');
          }
            // remove commas in other field
            $(formSelectorID + '#webform-component-donation .other-amount-wrapper .form-text, '+formSelectorID + '#webform-component-donation .recurring-other-amount-wrapper .form-text').keyup(function(e) {

              // 188 = comma keycode
              if (e.keyCode == '188' || e.charCode == '188') {
                // By default the input field must be text for desktop
                $(this).val($(this).val().replace(/,/g, ''));
              }
            });

            if (paypalAvailable && win.width() <= 959) {
              var emailSub = $(formSelectorID + "#webform-component-payment-information--email-opt-in");
              emailSub.prependTo('#webform-component-extra-fields .fieldset-wrapper').addClass('emailoptin-moved');
              $('#edit-submitted-payment-information-email-opt-in label.option').text('Get ACLU email updates');
            }
            // Init recurring message size
            if (($(formSelectorID + '#recurring-message .message').is(':visible') && win.width() <= 767 ) || $("#donation-tabs").length > 0) {
              if ($(formSelectorID + DONATION_TYPE_QUERY).width() <= 589 || ($("#donation-tabs").length > 0 && $(formSelectorID + DONATION_TYPE_QUERY).width() <= 804)) {
                $(formSelectorID + '#recurring-message .message').css("min-width", "200px");
              } else if ($(formSelectorID + DONATION_TYPE_QUERY).width() > 589) {
                $(formSelectorID + '#recurring-message .message').css("min-width", "350px");
              }
            }
            // get the first fieldset after donation amounts
            function getDonationFormPos() {
              return ($(".default-form-wrapper #webform-component-donor-information").offset());
            }
            $('.default-form-wrapper ' + formSelectorID + '#webform-component-donation input[type="radio"]').each(function() {
              var parentElement = $(this).parent();
              var attr = parentElement.attr('for');
              // For some browsers, `attr` is undefined; for others,
              // `attr` is false.  Check for both.
              if ( typeof attr !== typeof undefined && attr !== false) {
                $(this).insertBefore(parentElement);
              }

              if ($(this).attr('value') >= 1000 && $(this).attr('value') <= 9999) {
                optionClass[form_id] = "medium";
              } else if ($(this).attr('value') >= 10000) {
                optionClass[form_id] = "small";
              } else {
                optionClass[form_id] = "";
              }
              $(this).addClass(optionClass[form_id]);
            });
            $(".default-form-wrapper " + formSelectorID + "#recurring-message").insertAfter($(formSelectorID + DONATION_TYPE_QUERY));
            $(".node-type-donation-form .default-form-wrapper " + formSelectorID + "#webform-component-extra-fields").appendTo($(formSelectorID + '#webform-component-donation'));
            $(".node-type-p2p-donation-form .default-form-wrapper " + formSelectorID + "#webform-component-extra-fields").appendTo($(formSelectorID + '#webform-component-donation'));

            // The payment options radio button group will be hidden by default with css and styled to look like buttons
            // Add these css styled buttons to the tab index for keyboard navigation and the aria role to identify these elements as buttons for assistive technology
            // proper aria role button usage is to add aria-label attribute as well
            // It is not best practice to change a div to button using role but the is the best way to support keyboard and mouse events and support the UX business choice to change radio options to buttons
            $(formSelectorID + '#webform-component-extra-fields--payment-options input[type="radio"][value="0"]').parent()
              .addClass('pay-with-cc')
              .attr('role', 'button')
              .attr('tabindex', 0)
              .attr('aria-label', $("label[for='" +$(formSelectorID + '#webform-component-extra-fields--payment-options input[type="radio"][value="0"]').attr('id') + "']").text());
            $(formSelectorID + '#webform-component-extra-fields--payment-options input[type="radio"][value="1"]').parent()
              .addClass('pay-with-other pay-with-paypal')
              .attr('role', 'button')
              .attr('tabindex', 0)
              .attr('aria-label', $("label[for='" +$(formSelectorID + '#webform-component-extra-fields--payment-options input[type="radio"][value="1"]').attr('id') + "']").text());
            $(formSelectorID + '#webform-component-extra-fields--payment-options input[type="radio"][value="2"]').parent()
              .addClass('pay-with-other pay-with-applepay')
              .attr('role', 'button')
              .attr('tabindex', 0)
              .attr('aria-label', $("label[for='" +$(formSelectorID + '#webform-component-extra-fields--payment-options input[type="radio"][value="2"]').attr('id') + "']").text());
            $(formSelectorID + '#webform-component-extra-fields--payment-options input[type="radio"][value="3"]').parent()
              .addClass('pay-with-other pay-with-bank')
              .attr('role', 'button')
              .attr('tabindex', 0)
              .attr('aria-label', $("label[for='" +$(formSelectorID + '#webform-component-extra-fields--payment-options input[type="radio"][value="3"]').attr('id') + "']").text());

            // When a donation form congifured with Apple Pay loads, there's no way to know
            // if Apply Pay can be used on the device. An asyncronous process fires and if
            // Apple Pay isn't available the Apple Pay payment option is removed. Therefore,
            // in the section below, if the Apple Pay payment option is available, we do the
            // setup an Apple Pay form. If the async process comes back and removes the Apple
            // Pay payment option, we reverse that setup.
            (function() {
              var applepayPaymentMethodRadio = $("input[name='submitted[payment_information][payment_method]']").filter(function() { return this.value == 'applepay' });

              // If there is a standard Springboard Apple Pay button do some setup.
              if (applepayPaymentMethodRadio.length > 0) {
                $('body').addClass('applepay-available');
                $("#edit-submit").addClass("apple-pay-button-black apple-pay-button");

                // We need to be able to hide the Apple Pay button (which is really the form submit
                // button) when a monthly donation is made. Add a class to the body tag that indicates
                // the donation type so we can apply the appropriate styles.
                function updateDonationTypeBodyClass() {
                  var donationType;
                  if (getFrequencyRadioButtons().length) {
                    // we're on a switcher form, get the donation type.
                    donationType = getDonationFrequency();
                  } else if ($("input[type='hidden'][name='submitted[donation][recurs_monthly]']").length) {
                    // we're not on a switcher, get the donation type.
                    donationType = $("input[type='hidden'][name='submitted[donation][recurs_monthly]']").val();
                  }
                  
                  // Let's hide the Paypal option when Apple Pay is available.
                  // We're doing this because we need to keep the applepayPaymentMethodRadio
                  // radio selected in order to have 1-click Apple Pay donations. There's no good
                  // way to keep it selected if a user bails on a Paypal payment.
                  // apple pay and paypal are only available for one-time payments
                  if (donationType == ONE_TIME_FREQUENCY) {
                    $('body').addClass('onetime-donation-selected').removeClass('monthly-donation-selected').removeClass('annual-donation-selected');
                  } else if (donationType == MONTHLY_FREQUENCY ) {
                    $('body').addClass('monthly-donation-selected').removeClass('onetime-donation-selected').removeClass('annual-donation-selected');
                  } else if (donationType == ANNUAL_FREQUENCY) {
                    $('body').addClass('annual-donation-selected').removeClass('onetime-donation-selected').removeClass('monthly-donation-selected');
                  } else  {
                    $('body').removeClass('onetime-donation-selected').removeClass('monthly-donation-selected').removeClass('annual-donation-selected');
                  }
                }
                updateDonationTypeBodyClass();
                $("input[name='submitted[donation][recurs_monthly]']").change(updateDonationTypeBodyClass);
              } else {
                $('.pay-with-applepay').remove();
              }

              // If the Apple Pay button is removed, reverse the Apple Pay setup above.
              var origSelectedPaymentMethodRadio = $("input[name='submitted[payment_information][payment_method]']:checked");
              applepayPaymentMethodRadio.bind('destroyed', function() {
                applepayPaymentMethodRadio = null;
                $('body').removeClass('applepay-available');
                $("#edit-submit").removeClass("apple-pay-button-black apple-pay-button");
                $('.pay-with-applepay').remove();
                origSelectedPaymentMethodRadio.prop('checked', true);
                Drupal.acluDonationFormToggleChooseMethodText();
              });
            })();

            if (!$('#webform-component-extra-fields .message-wrapper').length) {
              $('#webform-component-extra-fields').append('<div class="message-wrapper" style="margin-top: 20px;"></div>');
            }

            $('.singlestep-donation ' + formSelectorID + ".pay-with-cc").click(function(e) {
              if ($('#webform-component-extra-fields .message-wrapper').length && !$('#webform-component-extra-fields .message-wrapper').is(':empty')) {
                $('#webform-component-extra-fields .message-wrapper').empty();
              }
              showForm(formSelectorID);
              var ccRadio = $(formSelectorID).find("input[name='submitted[payment_information][payment_method]']").filter(function() { return this.value == 'credit' });
              ccRadio.trigger('click');
              // If pay with credit card is clicked jump to the form
              var donationStartOffset = getDonationFormPos();
              $(jQuery.browser.webkit ? "body": "html").animate({
                scrollTop : parseInt(donationStartOffset.top, 10)
              }, 1000);
            });

           var paypalRadio = $('.singlestep-donation ' + formSelectorID).find("input[name='submitted[payment_information][payment_method]']").filter(function() { return this.value == 'paypal' });
            paypalRadio.click(function(e) {
              Drupal.behaviors.DonationFormJS.acluSubmitPaypal(e);
            });

            $('.singlestep-donation ' + formSelectorID).find('.pay-with-paypal').click(function(e){
              var paypalRadio = $(formSelectorID).find("input[name='submitted[payment_information][payment_method]']").filter(function() { return this.value == 'paypal' });
              // validate form before sending to paypal
              var checkedVal = $('#webform-component-donation--amount input:radio:checked').val();
              if (typeof checkedVal != 'undefined') {
                if ($('#webform-component-extra-fields .message-wrapper').length && !$('#webform-component-extra-fields .message-wrapper').is(':empty')) {
                  $('#webform-component-extra-fields .message-wrapper').empty();
                }

                // Trigger paypal radio click
                jQuery(paypalRadio).click();

              } else {
                // Manually display error message - amount is required for paypal
                var errorMsg = '<div class=".alert.error" role="alert" style="border: solid 1px #cc0000; color: #cc0000"><button type="button" class="sb-msg-close" data-dismiss="alert">×</button><h2 class="element-invisible">Error message</h2><ul style="margin: 0"><li aria-label="Your donation amount must be greater than or equal to $5.00">Your donation amount must be greater than or equal to 5.00.</li></ul></div>';
                $('#webform-component-extra-fields .message-wrapper').html(errorMsg);
              }
              hideForm(formSelectorID);
            });
          
            /*
            /* Check if the currently selected Donation Frequency has the
            /* "other radio amount" selected. 
            /* - If it does display the other input text field.
            /* 
            /* - Monthly and Annual are recurring donations and share the same other input text field.
            /*   That field is marked with the class: .recurring-other-amount-wrapper
            /*
            /* - One-time is a non-recurring donation and has a separate other input text field.
            /*   That field is marked with the class: .other-amount-wrapper
            */
            function toggleCurrentFrequencyOtherAmountInput(amountVal) {
              // singlestep donation forms desktop view should always have the other input field visible
              // multistep donation forms and mobile singlestep donation forms should have the other field hidden if 
              // the other radio amount is not selected
              const hideOtherField = ($('.multistep-donation').length || $(window).width() <= 959)

              const donationType = getDonationFrequency()

              if ((donationType === MONTHLY_FREQUENCY) || (donationType === ANNUAL_FREQUENCY)) {
                if (amountVal == 'other') {
                  $('.recurring-other-amount-wrapper').css('display', showOtherField).find('input').focus();
                } else {
                  if (hideOtherField) {
                    $('.recurring-other-amount-wrapper').hide()
                  }
                  // the form will not submit properly if there is a value in the other 
                  // input field and other is not selected for the selected donation frequency
                  $('.recurring-other-amount-wrapper').find('input').val('');
                }
              } else if (donationType === ONE_TIME_FREQUENCY) {
                if (amountVal == 'other') {
                  $('.other-amount-wrapper').css('display', showOtherField).find('input').focus();
                 }  else {
                  if (hideOtherField) {
                    $('.other-amount-wrapper').hide()
                  }
                  // other is not selected reset the other input field for this frequency.
                  $('.other-amount-wrapper').find('input').val('');
                }
              } else {
                // single step or non-switcher multistep (monthly and one-time only forms)
                // when there is only one donation frequency there is only one other input field marked
                // with the .other-amount-wrapper class.
                if (amountVal == 'other') {
                  $('.other-amount-wrapper').css('display', showOtherField).find('input').focus();
                }  else {
                  if (hideOtherField) {
                    $('.other-amount-wrapper').hide()
                  }
                  // other is not selected reset the other input field if it exists.
                  $('.other-amount-wrapper').find('input').val('');
                }
              }
            }

            // Other input field toggle for donation radio amount buttons
            function amountMethodButtonEvent(e) {
              const currentRadio = $(e.currentTarget).children(':radio');
              currentRadio.attr('checked', true);
              $(e.currentTarget).children(':radio').trigger('change')
              toggleCurrentFrequencyOtherAmountInput(currentRadio.val())
            }

            const oneTimeAmountGroupName =  '.multistep-donation div.form-type-radio.form-item-submitted-donation-amount'
            const monthlyAmountGroupName = '.multistep-donation div.form-type-radio.form-item-submitted-donation-recurring-amount'
            const annualAmountGroupName = '.multistep-donation div.form-type-radio.form-item-frequencies-sb-fs-annually-amount'
            $(annualAmountGroupName).add(monthlyAmountGroupName).add(oneTimeAmountGroupName).keyup(function(e){
              if (e.key === "Enter" ) {
                // 'Enter' key logic is handled in the keydown listener
                e.preventDefault();
                return false;
              }
            });
            $(annualAmountGroupName).add(monthlyAmountGroupName).add(oneTimeAmountGroupName).keydown(function(e) {
              if (e.key === " " || e.key === "Enter" || e.key === "Spacebar" ) {
                amountMethodButtonEvent(e)
                return false;
              }
            });
            $(annualAmountGroupName).add(monthlyAmountGroupName).add(oneTimeAmountGroupName).click(function(e) {
                amountMethodButtonEvent(e)
                return false;
            });
            $(".singlestep-donation input[name='submitted[donation][amount]']").click(function(e) {
              const currentRadio = $(e.currentTarget);
              toggleCurrentFrequencyOtherAmountInput(currentRadio.val())
            });
            if (paypalAvailable) {
              // Hide the default paypal button at the bottom of the page for forms in donation tabs
              var defaultPaypalRadio = $(formSelectorID).find("input[name='submitted[payment_information][payment_method]']").filter(function() { return this.value == 'paypal' });
              var defaultPaypal = defaultPaypalRadio.parent('.form-item');
              defaultPaypal.addClass('paypal-radio');
              // Don't hide the submit button if there is no paypal button
              $(formSelectorID).wrapInner("<div class='donation-buttons-wrapper'></div>");
              if (recurringClicked) {
                defaultPaypal.hide();
              }
              // Toggle Frequency radio amount buttons
              // NOTE: the variable names reflect that monthly was the only recurring payment before annual was enabled.
              // With annual now visible on the first step, there are two recurring payment types.
              // but for the MVP  lanuch only credit card can be used for annual donation.
              // We need to cleanup the variable names in a spin-off ticket after the business logic for this code
              // is documentated.
              getFrequencyRadioButtons().change(function(e){
                const formSelectorID = "#" + $(this).closest('form').attr('id') + " ";
                const additionalPaymentButtons = $(formSelectorID + '#webform-component-extra-fields--payment-options .form-item-submitted-extra-fields-payment-options.pay-with-other, #webform-component-extra-fields--payment-options .pay-with-daf.pay-with-other');
                // recurringPaymentClasses == monthly only for now
                const recurringPaymentClasses = '.pay-with-bank, .pay-with-daf';
                // recurringButtons == EFT
                const recurringButtons = additionalPaymentButtons.filter(recurringPaymentClasses);
                // oneTimeButtons == PayPal and Apple Pay
                const oneTimeButtons = additionalPaymentButtons.not(recurringPaymentClasses);
                // Only credit card for annual payments for iniitial launch
                const annualButtons = recurringButtons.not(recurringPaymentClasses);

                /* Initialze donation frequency amount radio button parent constant
                /* These are the names springboard generates when each of the frequencies are enabled
                /* via the springboard form config
                */
                const oneTimeAmountGroupName =  'div.form-type-radio.form-item-submitted-donation-amount'
                const monthlyAmountGroupName = 'div.form-type-radio.form-item-submitted-donation-recurring-amount'
                const annualAmountGroupName = 'div.form-type-radio.form-item-frequencies-sb-fs-annually-amount'
           
                const oneTimeRadio = $(oneTimeAmountGroupName).children(':radio').filter(':checked').val()
                const monthlyRadio = $(monthlyAmountGroupName).children(':radio').filter(':checked').val()
                const annualRadio = $(annualAmountGroupName).children(':radio').filter(':checked').val()
        
                const donationType = getDonationFrequency()

                if (donationType == MONTHLY_FREQUENCY) {
                  // Monthly is selected - show the monthly radio amount buttons
                  defaultPaypal.hide();
                  defaultPaypalRadio.attr("disabled", true);
                  $('.progress-wrap').addClass('progress-wrap--monthly');
                  $(formSelectorID + '#webform-component-extra-fields--payment-options .form-item-submitted-extra-fields-payment-options.pay-with-other').show();
                  oneTimeButtons.hide();
                  annualButtons.hide();
                  recurringButtons.show();
                  if(recurringButtons.length === 0) {
                    $(formSelectorID + '#webform-component-extra-fields--payment-message').show().css('display', 'inline-block');
                  } else {
                    $(formSelectorID + '#webform-component-extra-fields--payment-message').hide();
                  }
                  toggleCurrentFrequencyOtherAmountInput(monthlyRadio)
                 } else if (donationType == ONE_TIME_FREQUENCY) {
                    // One-time is selected  - show the one-time radio amount buttons
                    defaultPaypal.show();
                    defaultPaypalRadio.attr("disabled", false);
                    $('.progress-wrap').removeClass('progress-wrap--monthly');
                    $(formSelectorID + '#webform-component-extra-fields--payment-message').hide();
                    recurringButtons.hide();
                    annualButtons.hide()
                    oneTimeButtons.show();
                    toggleCurrentFrequencyOtherAmountInput(oneTimeRadio)
                } else if (donationType == ANNUAL_FREQUENCY) {
                   // Annual is selected - show the annual radio amount buttons
                   defaultPaypal.hide();
                   defaultPaypalRadio.attr("disabled", true);
                   $('.progress-wrap').addClass('progress-wrap--monthly');
                   $(formSelectorID + '#webform-component-extra-fields--payment-options .form-item-submitted-extra-fields-payment-options.pay-with-other').show();
                   oneTimeButtons.hide();
                   recurringButtons.hide();
                   annualButtons.show()
                   if(annualButtons.length === 0) {
                     $(formSelectorID + '#webform-component-extra-fields--payment-message').show().css('display', 'inline-block');
                   } else {
                     $(formSelectorID + '#webform-component-extra-fields--payment-message').hide();
                   }                   
                   toggleCurrentFrequencyOtherAmountInput(annualRadio)
                }
              });
              getFrequencyRadioButtons().trigger('change');

              // onload check default options
              $(formSelectorID + "#webform-component-extra-fields--payment-options").show().css('display', 'inline');
              $(formSelectorID + "#webform-component-extra-fields--payment-options > .form-item").css('display', 'inline');

            }
            // /////////////mobile specific button behavior for non-multistep forms
            if ($(window).width() <= 767 && $('#container.singlestep-donation').length) {
              $(formSelectorID + "#edit-submitted-donation-recurs-monthly-1").click(function(e) {
                recurringClicked = false;
                var amtOffset = $(formSelectorID + "#webform-component-donation--amount").offset();
                $(jQuery.browser.webkit ? "body": "html").animate({
                  scrollTop : parseInt(amtOffset.top, 10),
                }, 1000);
              });
              $(formSelectorID + "#edit-submitted-donation-recurs-monthly-2").click(function(e) {
                recurringClicked = true;
                var amtOffset = $(formSelectorID + "#webform-component-donation--amount").offset();
                $(jQuery.browser.webkit ? "body": "html").animate({
                  scrollTop : parseInt(amtOffset.top, 10)
                }, 1000);
              });
              // Donation Amount radio buttons add on scrolling functionality
              var donationAmountRadios = $("#webform-component-donation input[type='radio']").filter(function() {
                return((" " + $(this).attr('id') + " ").match(/edit-submitted-donation-.*amount.*-\d*/) != null);
              })
              if (paypalAvailable) {
                // if paypal is availbale
                // go to the payment options next (pay by credit or pay by paypal)
                donationAmountRadios.each(function() {
                  if ($(this).attr('value') !== "other") {
                    $(this).click(function(e) {
                      if (recurringClicked = true) {
                        var paymentOptionsOffset = $("#webform-component-extra-fields").offset();
                        if ($('#edit-submitted-donation-other-amount-wrapper').is(':visible')) {
                          paymentOptionsOffset.top -= 95;
                        }
                        $(jQuery.browser.webkit ? "body": "html").animate({
                          scrollTop : parseInt(paymentOptionsOffset.top, 10)
                        });
                      } else {
                        recurringClicked = false;
                      }
                    });
                  }
                });
              } else {
                // If paypal is not availble go to the donor information fields next.
                donationAmountRadios.each(function() {
                  if ($(this).attr('value') !== "other") {
                    $(this).click(function(e) {
                      var donationStartOffset = getDonationFormPos();
                      if ($(formSelectorID + '#edit-submitted-donation-other-amount-wrapper').is(':visible')) {
                        donationStartOffset.top -= 95;
                      }
                      $(jQuery.browser.webkit ? "body": "html").animate({
                        scrollTop : parseInt(donationStartOffset.top, 10) - 15
                      }, 1000);
                    });
                  }
                });
              }
            }

            /**
             * Parse the raw querystring into an object.
             */
            var urlParams = {};
            (function() {
              var parsed, regex = /([^&=]+)=?([^&]*)/g, decode = function(value) {
                // Regex for replacing addition symbol with a space
                return decodeURIComponent(value.replace(/\+/g, " "));
              }, querystring = window.location.search.substring(1);
              while ( parsed = regex.exec(querystring)) {
                var keyname = new String(decode(parsed[1]));
                urlParams[keyname.toLowerCase()] = decode(parsed[2]);
              }
            })();

            // If error messages are visible make sure the form is visible
            if ($(formSelectorID + ".form-message-intro").length) {
              // if paypal is available and the paypal button is selected don't show the form
              if (paypalAvailable && paypalChecked) {
                hideForm(formSelectorID);
              } else {
                showForm(formSelectorID);
              }
            }

          });
        });
        if (($(window).width() <= 959)) {
          // default compact flow js will make fieldsets visible on load, hide them again but just run once so that subsequent method changes are not affected
          $(document).one('braintree.methodChange', function(event, paymentMethod) {
            if (paymentMethod == 'credit' && !Drupal.settings.braintree.paypal.collapsed) {
              $('#webform-component-donor-information').hide();
              $('#webform-component-billing-information').hide();
              if (getDonationFrequency() === 'NO_RECUR') {
                // Hide monthly gift notice
                $("#webform-component-extra-fields--payment-message").hide();
              }
            }
          });
        }
        $(window).resize(function() {
          win = $(window);
          clearTimeout(this.id);
          this.id = setTimeout(doneResizing, 500);
          // show email opt-in on mobile when resizing
          var form_id = $(".node form.fundraiser-donation-form").attr('id');
          var formSelectorID = "#" + form_id + " ";
          var emailSub = $(formSelectorID + "#webform-component-payment-information--email-opt-in");
          var emailSub2 = $(formSelectorID + "#webform-component-payment-information--profile-may-we-share-your-info");
          var emailSubLabel = "Sign up for ACLU emails to stay informed and take action to protect people's rights.";
          if (emailSub.length && win.width() > 959) {
            // If email opt-in has been moved, restore it
            emailSub.insertAfter('#webform-component-payment-information--payment-fields');
            // If email share has been moved, restore it too
            emailSub2.insertAfter('#webform-component-payment-information--email-opt-in');
            $('#edit-submitted-payment-information-email-opt-in label.option').text(emailSubLabel);
            emailSub.removeClass('emailoptin-moved');
            if ($(formSelectorID + DONATION_FREQUENCY_QUERY).val() === 'NO_RECUR') {
              // Hide monthly gift notice
              $("#webform-component-extra-fields--payment-message").hide();
            }
          }
          if (!emailSub.hasClass('.emailoptin-moved') && paypalAvailable && win.width() <= 959) {
            // Move 'Get updates on the Fight for Freedom' checkbox
            emailSub.prependTo('#webform-component-extra-fields .fieldset-wrapper').addClass('emailoptin-moved');
            $('#edit-submitted-payment-information-email-opt-in label.option').text('Get ACLU email updates');
            if ($(formSelectorID + DONATION_FREQUENCY_QUERY).val() === 'NO_RECUR') {
              // Hide monthly gift notice
              $("#webform-component-extra-fields--payment-message").hide();
            }
          }
          function doneResizing() {
            win = $(window);
            if (win.width() <= 767) {
              if ($(formSelectorID + DONATION_TYPE_QUERY).width() <= 589) {
                $(formSelectorID + '#recurring-message .message').css("min-width", "200px");
              } else if ($(formSelectorID + DONATION_TYPE_QUERY).width() > 589) {
                $(formSelectorID + '#recurring-message .message').css("min-width", "350px");
              }
            }
            if (win.width() > 959) {
              showForm(formSelectorID);
              $("#webform-component-extra-fields--payment-options").hide().css('display', 'none');
              // On desktop the other input field should always show
              // check for switcher form to see which other input field to show
              if (getFrequencyRadioButtons().length && getDonationFrequency().match(/^NO_RECUR(.*)/i)) {
                if ($('#webform-component-donation--amount input:radio[name="submitted[donation][amount]"]:checked').val() == 'other') {
                  $('.other-amount-wrapper').css('display', showOtherField).find('input').focus();
                } else {
                  $('.singlestep-donation .other-amount-wrapper').css('display', showOtherField).focus();
                }
              } else if (getFrequencyRadioButtons().length && (getDonationFrequency() === MONTHLY_FREQUENCY || getDonationFrequency() === ANNUAL_FREQUENCY)) {
                if (isMonthlyOtherRadioSelected() || isAnnualOtherRadioSelected()) {
                  $(".recurring-other-amount-wrapper").css('display', showOtherField).find('input').focus();
                } else {
                  $(".singlestep-donation .recurring-other-amount-wrapper").css('display', showOtherField).find('input').focus();
                }
              } else {
                $('.singlestep-donation .recurring-other-amount-wrapper').css('display', showOtherField).find('input').focus();
              }
            } else {
              const donationType = getDonationFrequency()
              $("#webform-component-extra-fields--payment-options").show();
              if ($('.singlestep-donation #webform-component-donation--amount input:radio[name="submitted[donation][amount]"]:checked').val() == 'other') {
                $('.other-amount-wrapper').css('display', showOtherField).find('input').focus();
              } else if (donationType === ONE_TIME_FREQUENCY && isOneTimeOtherRadioSelected()) {
                $(".other-amount-wrapper").css('display', showOtherField).find('input').focus();
              } else if (donationType === MONTHLY_FREQUENCY && isMonthlyOtherRadioSelected()) {
                $(".recurring-other-amount-wrapper").css('display', showOtherField).find('input').focus();
              } else if (donationType == ANNUAL_FREQUENCY && isAnnualOtherRadioSelected()) {
                $(".recurring-other-amount-wrapper").css('display', showOtherField).find('input').focus();
              } else if ($('input:radio[name="submitted[donation][amount]"]:checked').val() != 'other') {
                $(".other-amount-wrapper, .recurring-other-amount-wrapper").hide();
              }
            }
          }
        });
      });
    }
  }

  // Add or remove the 'Choose payment method' text as appropriate
  Drupal.acluDonationFormToggleChooseMethodText = function() {
    if (Drupal.acluDonationFormShouldDisplayChooseMethodText()) {
      if ($('.payment-method-message').length < 1){
        $('#webform-component-extra-fields--payment-options').prepend( $('<div class="payment-method-message">Choose payment method</div>') );
      }
    } else {
      $('.payment-method-message').remove();
    }
  };

  // Count the number of payment methods available.
  // If more than 1, display the 'choose payment method' text.
  Drupal.acluDonationFormShouldDisplayChooseMethodText = function() {
    var numPaymentMethodsAvailable = Drupal.acluDonationFormGetNumberOfPaymentMethods();
    return (
      numPaymentMethodsAvailable > 1
      && !(
        // if only Apple Pay and Paypal are available,
        // don't display the 'choose payment method' text since we
        // hide Paypal on devices with Apple Pay available thus leaving
        // us with only one visible payment method.
        numPaymentMethodsAvailable == 2 &&
        $( 'input[name="submitted[payment_information][payment_method]"][value="applepay"]' ).length == 1 &&
        $( 'input[name="submitted[payment_information][payment_method]"][value="paypal"]' ).length == 1
      )
      && (
        // Hide the text if you have a recurring donation and only credit is available.
        // EFT payments are only available for monthly for now.
        $('input[name="submitted[donation][recurs_monthly]"]:checked').val() == ONE_TIME_FREQUENCY ||
        ($('input[name="submitted[payment_information][payment_method]"][value="bank account"]' ).length == 1 && $('input[name="submitted[donation][recurs_monthly]"]:checked').val() !== ANNUAL_FREQUENCY))
    );
  };

  Drupal.acluDonationFormGetNumberOfPaymentMethods = function() {
    return $('#edit-submitted-payment-information-payment-method .form-item-submitted-payment-information-payment-method').length;
  };
})(jQuery);
