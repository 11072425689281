/**
 * This code overrides the default Springboard thermometer functionality to make
 * the thermometer automatically disappear when it's reached a certain percentage
 * of the goal so that we do not have to manually remove it.
 *
 * When the `aclu-goal-autodisappearing` class is used, the thermometer will
 * automatically be hidden either at a default 95% or at a custom percentage with
 * the addition of the `data-hide-at-percentage` attribute.
 *
 * To make a goal auto-disappear:
 * 1) Add the class `aclu-goal-autodisappearing` to the HTML element that has a `data-gid`
 * attribute. If there is an existing `wg-goal` class, it should be removed.
 *
 * 2) Thermometer is set to disappear at 95% by default. If you want a different
 * percentage, pass in the optional `data-hide-at-percentage` attribute.
 * For example: `data-hide-at-percentage="90"`.
 */

(function ($) {
  Drupal.behaviors.webform_goals = {
    attach: function (context, settings) {
      var ids = [];
      items = jQuery(".aclu-goal-autodisappearing").map(function () {
        jQuery(this).hide();
        ids.push(jQuery(this).data("gid"));
      });
      // We usually have the same goal id multiple times in the body/summary section of a form.
      // We do not need to loop over the same goal id multiple times.
      // So, let's only count each goal id once.
      const idsSet = new Set(ids);
      const idsArray = [...idsSet];
      goal_ids = idsArray.join(",");
      if (goal_ids) {
        jQuery.ajax({
          dataType: "jsonp",
          data: "goal_ids=" + goal_ids,
          jsonp: "jsonp_callback",

          url: Drupal.settings.webform_goals.loader_url,
          success: function (data) {
            // unpack data and place accordingly.
            //  data format:
            //    - status
            //    - id (eg 14)
            //      - markup (widget markup)
            //      - tokens
            //         - token (eg. [webform_goal:progress-raw]) => token replacement value (eg 11)
            for (i = 0; i < idsArray.length; ++i) {
              jQuery(".aclu-goal-autodisappearing").each(function () {
                if (jQuery(this).data("gid") === idsArray[i]) {
                  var percentRemaining = 100;
                  var hideAtPercentage = 95;
                  if (
                    jQuery(this).is(":empty") ||
                    jQuery(this).html() === "\n" ||
                    jQuery(this).html() === "&nbsp;"
                  ) {
                    // embed widget mode
                    if (typeof data[idsArray[i]] != "undefined") {
                      jQuery(this).html(data[idsArray[i]].markup);
                    }
                  } else {
                    element = jQuery(this);
                    if (jQuery(this).data("hide-at-percentage")) {
                      hideAtPercentage = jQuery(this).data("hide-at-percentage");
                    }
                    // token replacement mode
                    tokens = data[idsArray[i]].tokens;
                    jQuery.each(tokens, function (index, value) {
                      html = element.html().replace(index, value);
                      element.html(html);
                      if (index === "[webform_goal:progress-remaining-percent]") {
                        percentRemaining = value;
                      }
                    });
                  }
                  const percentFilled = 100 - percentRemaining;
                  if (percentFilled <= hideAtPercentage ) {
                    jQuery(this).show();
                  }
                }
              });
            }
          },
        });
      } // end if
    },
  };
})(jQuery);
