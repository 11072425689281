/* eslint
    func-names: "off",
    eqeqeq: "off",
    object-shorthand: "off",
    camelcase: "off",
*/
(function ($) {
  const { selectOneTimeDonationRadioButton, getFrequencyRadioButtons, MONTHLY_FREQUENCY, DONATION_RECURRING_QUERY } = require('./utils/donation-form-helper.js')

   /*
    * Code for the Donation Form: init_step parameter
    *
    * The code steps and display steps are different
    *
    * Code -> Display Mapping
    * step 0 -> 1 - Amount
    * step 1 -> 2 - Contact
    * step 2 -> 3 - Billing
    * step 3 ->	4 - Credit or Bank account
    *
    * NOTE: This was initally built to support init_step = 1 which provideds the ability to fill in all
    * fields on step 0 then initally load the form on step 1, which is why its in a behavior
    * called 'DonationFormSkipStep'
    *
    * This code processes the following query parameters
    *
    * amount = radio amount for one-time
    * other_amount = other input amount for one-time
    * recurs_monthly = recurs || no_recurr
    * recurring_amount = radio amount for monthly
    * recurring_other_amount = other input amount for monthly
    * email_opt_in = check email optin
    * payment_method = credit || eft
    * init_step = 0 = fill in applicable query params, stay on step 0
    * init_step = 1 = fill in applicable query params, skip to step 1
    * .
    *
  */
  Drupal.behaviors.DonationFormSkipStep = {
    attach: function (context, settings) {
      function showOnce(v) {
        var oneTime = $("#webform-component-donation--amount", v);
        var recurs = $("#webform-component-donation--recurring-amount", v);
        var switcher = $(
          DONATION_RECURRING_QUERY,
          v
        );
        if (oneTime.length > 0 && recurs.length > 0 && switcher.length > 0) {
          // Set regular options, hide recurring options.
          // Deselect all amount values when switching.
          $(
            "input:radio[name='submitted[donation][recurring_amount]']:checked",
            v
          ).attr("checked", false);
          $("input:radio[name='submitted[donation][amount]']:checked", v).attr(
            "checked",
            false
          );
          if (
            $(".singlestep-donation #webform-component-donation").width() > 767
          ) {
            // on desktop make sure other input shows by default
            $("#webform-component-donation--other-amount").css(
              "display",
              "inline-flex"
            );
          } else {
            $("#webform-component-donation--other-amount").css(
              "display",
              "none"
            );
          }
          $(
            "#webform-component-donation input#edit-submitted-donation-recurring-other-amount"
          ).val("");
          $(
            "#webform-component-donation input#edit-submitted-donation-other-amount"
          ).val("");
          $(".progress-wrap").removeClass("progress-wrap--monthly");
          selectOneTimeDonationRadioButton()
        }
      }

      function showRecurring(v) {
        var oneTime = $("#webform-component-donation--amount", v);
        var recurs = $("#webform-component-donation--recurring-amount", v);
        var switcher = $(
          DONATION_RECURRING_QUERY,
          v
        );

        if (oneTime.length > 0 && recurs.length > 0 && switcher.length > 0) {
          // Set regular options, hide recurring options.
          // Deselect all amount values when switching.
          $(
            "input:radio[name='submitted[donation][recurring_amount]']:checked",
            v
          ).attr("checked", false);
          $("input:radio[name='submitted[donation][amount]']:checked", v).attr(
            "checked",
            false
          );
          if (
            $(".singlestep-donation #webform-component-donation").width() > 767
          ) {
            // on desktop make sure other input shows by default
            $("#webform-component-donation--recurring-other-amount").css(
              "display",
              "inline-flex"
            );
          } else {
            $("#webform-component-donation--recurring-other-amount").css(
              "display",
              "none"
            );
          }
          $(
            "#webform-component-donation input#edit-submitted-donation-other-amount"
          ).val("");
          $(".progress-wrap").addClass("progress-wrap--monthly");
          $('#edit-submitted-donation-recurs-monthly input[type="radio"][value="recurs"]').click()
        }
      }
      function triggerPayPal(data) {
        if (!data) {
          return;
        }
        // select one-time
        $("#edit-submitted-donation-recurs-monthly-1").click();
        if (data.isOtherAmount) {
          // select the 'other' one-time amount option
          $("#edit-submitted-donation-amount input[value='other']").click();
          // set the 'other' amount to amount on the form
          $("#edit-submitted-donation-other-amount").val(data.amount);
        } else {
          // click the button that corresponds to the passed-in amount
          $("#edit-submitted-donation-amount input[type='radio']").each(
            function () {
              if ($(this).val() === data.amount) {
                $(this).click();
              }
            }
          );
        }
        // force click on the paypal button
        $("#edit-submitted-extra-fields-payment-options-2").click();
      }

      // respond to trigger paypal message coming from outside the iframe
      // this is used by the giving banner step one overlay feature in the vue library
      window.addEventListener("message", function (event) {
        if (event.data.event_id === "triggerPaypal" && event.data) {
          triggerPayPal(event.data.data);
        }
      });

      if (Drupal.behaviors.DonationFormValidateSkipStep.attach()) {
        // Process query string for donation forms to set default amount values for one-time, monthly and switcher forms.
        // get query strings and override donation form default amount and recurring values if passed.
        var queryStrings = Drupal.behaviors.FormJS.getQueryParams(
          document.location.search
        );
        var {
          amount,
          other_amount,
          recurs_monthly,
          recurring_amount,
          recurring_other_amount,
          email_opt_in,
          payment_method,
        } = queryStrings;

        var isMultistep = $(".multistep-donation").length;

        // find email optin field, and set it to match query parameter
        var emailCheckbox = $('[name="submitted[donation][emailOptIn][1]"]');
        if (emailCheckbox.length === 0) {
          // some forms have a different name for this field
          emailCheckbox = $('[name="submitted[donation][email_opt_in][1]"]');
        }

        if (emailCheckbox.length > 0) {
          if (email_opt_in == 1) {
            emailCheckbox.prop("checked", true);
          } else if (email_opt_in == 0) {
            emailCheckbox.prop("checked", false);
          }
        }
        var switcher = getFrequencyRadioButtons();
        var showOtherField = isMultistep ? "block" : "inline-flex";

        // Set recurring/switcher radios first since amounts can be different
        if (
          $(
            '#webform-component-donation #edit-submitted-donation-recurs-monthly input[value="' +
              recurs_monthly +
              '"]'
          ).length > 0
        ) {
          $(
            '#webform-component-donation #edit-submitted-donation-recurs-monthly input[value="' +
              recurs_monthly +
              '"]'
          ).attr("checked", true);
        }
        // Show the applicable radio buttons
        if (recurs_monthly == MONTHLY_FREQUENCY) {
          showRecurring(context);
        } else if (recurs_monthly.toLowerCase() ==  "no_recurr") {
          showOnce(context);
        }

        // set the amount parameter
        if (recurs_monthly == MONTHLY_FREQUENCY && switcher.length > 0) {
          if (
            $(
              '#webform-component-donation #edit-submitted-donation-recurring-amount input[value="' +
                recurring_amount +
                '"]'
            ).length > 0
          ) {
            // an actual value
            $(
              '#webform-component-donation #edit-submitted-donation-recurring-amount input[value="' +
                recurring_amount +
                '"]'
            ).attr("checked", true);
            if (
              $(".singlestep-donation #webform-component-donation").width() >
              767
            ) {
              // on desktop make sure other input shows by default
              $("#webform-component-donation--recurring-other-amount").css(
                "display",
                "inline-flex"
              );
            } else {
              $("#webform-component-donation--recurring-other-amount").css(
                "display",
                "none"
              );
            }
          }
          if (
            typeof recurring_other_amount !== "undefined" &&
            recurring_other_amount != "" &&
            recurring_other_amount != null
          ) {
            // set other recurring amount
            $(
              '#webform-component-donation #edit-submitted-donation-recurring-amount input[value="other"]'
            ).attr("checked", true);
            $("#webform-component-donation--recurring-other-amount").css(
              "display",
              showOtherField
            );
            $(
              "#webform-component-donation input#edit-submitted-donation-recurring-other-amount"
            )
              .focus()
              .val(recurring_other_amount)
              .blur();
          }
        } else {
          // this is a one-time amount or GOL only no switcher form
          if (
            $(
              '#webform-component-donation #edit-submitted-donation-amount input[value="' +
                amount +
                '"]'
            ).length > 0
          ) {
            // an actual value
            $(
              '#webform-component-donation #edit-submitted-donation-amount input[value="' +
                amount +
                '"]'
            ).attr("checked", true);
            if (
              $(".singlestep-donation #webform-component-donation").width() >
              767
            ) {
              // on desktop make sure other input shows by default
              $("#webform-component-donation--other-amount").css(
                "display",
                "inline-flex"
              );
            } else {
              $("#webform-component-donation--other-amount").css(
                "display",
                "none"
              );
            }
          }
          if (
            typeof other_amount !== "undefined" &&
            other_amount != "" &&
            other_amount != null
          ) {
            // set other amount
            $(
              '#webform-component-donation #edit-submitted-donation-amount input[value="other"]'
            ).attr("checked", true);
            $("#webform-component-donation--other-amount").css(
              "display",
              showOtherField
            );
            $(
              "#webform-component-donation input#edit-submitted-donation-other-amount"
            )
              .focus()
              .val(other_amount)
              .blur();
          }
        }

        // After form has been set from GET params scroll down to next fields user needs to address
        var donationFormStartPos = $("#webform-component-donation").offset();
        $(jQuery.browser.webkit ? "body" : "html").animate({
          scrollTop: parseInt(donationFormStartPos.top, 10),
        });

        // set payment method for monthly donations
        if (recurs_monthly == MONTHLY_FREQUENCY) {
          if (payment_method === "eft") {
            Drupal.behaviors.DonationMultistepHidePaymentFields("credit_card");
          } else if (payment_method === "credit_card") {
            Drupal.behaviors.DonationMultistepHidePaymentFields("bank");
          }
        }
      }
    },
  };

  // determine whether or not the page has the right query params set
  // and should skip step 1
  //
  // skip step sends the user straight to billing and payment steps
  // with the amount, frequency (e.g. one-time, monthly), and payment method pre-selected
  // via a URL with query params donating the pre-selected donation options
  Drupal.behaviors.DonationFormValidateSkipStep = {
    attach: function (context, settings) {
      function getMonthlyPaymentMethods() {
        // checks to see if the credit card and bank account payment methods
        // are enabled on the springboard form.
        // does NOT verify that there is a button available for the user to
        // donate via one of those payment methods

        var methods = { creditCardAvailable: false, eftAvailable: false };

        $(
          "#edit-submitted-extra-fields-payment-options .form-item input",
          context
        ).each(function (i, el) {
          // switches on the payment method's underlying value
          var paymentOptionInputValue = $(el).val();
          switch (paymentOptionInputValue) {
            case "0":
              methods.creditCardAvailable = true;
              break;
            case "3":
              methods.eftAvailable = true;
              break;
            default:
          }
        });
        return methods;
      }

       /*
       * Verifies that the required url parameters are included in order to
       * pre-fill fields and/or skip a step when the init_step parameter is used.
       *
       * This code only supports an init_step value of 0 or 1. Each of these values
       * indicates different behaviors and require different required parameters.
       *
       */
      function validateParams(init_step, recurrenceIsSet, amountIsSet, paymentMethodIsSet) {
        // init_step is required otherwise do not popuplate or skip a step
        if ( typeof init_step !== "undefined" ) {
          // These values are required to populate step 0 and skip to step 1 when init_step =
          // per the "Code -> Display Mapping" table in the comment at the top of this page
          if ( (init_step == 1) && recurrenceIsSet && amountIsSet && paymentMethodIsSet ) {
            return true
          } else if ( (init_step == 0) && recurrenceIsSet ) {
            return true
          }
        }
        return false
      }

      // Process query string for donation forms to set default amount values for one-time, monthly and switcher forms.
      // get query strings and override donation form default amount and recurring values if passed.
      var queryStrings = Drupal.behaviors.FormJS.getQueryParams(
        document.location.search
      );
      var {
        init_step,
        amount,
        other_amount,
        recurs_monthly,
        recurring_amount,
        recurring_other_amount,
        payment_method,
      } = queryStrings;
      var recurrenceIsSet = typeof recurs_monthly !== "undefined";
      var amountIsSet =
        typeof amount !== "undefined" ||
        typeof other_amount !== "undefined" ||
        typeof recurring_amount !== "undefined" ||
        typeof recurring_other_amount !== "undefined";
      var paymentMethodIsSet =
        payment_method === "eft" || payment_method === "credit_card";
      if (!paymentMethodIsSet) {
        // unknown or unset payment method in query string
        // determine if there's only one available monthly payment method
        var { creditCardAvailable, eftAvailable } = getMonthlyPaymentMethods();
        if (creditCardAvailable && eftAvailable) {
          // if there is more than one payment method option available, send the user back to step one
          paymentMethodIsSet = false;
        } else if (eftAvailable) {
          // if there's only one available payment method, set to that and allow skip step
          Drupal.behaviors.DonationMultistepHidePaymentFields("credit_card");
          paymentMethodIsSet = true;
        } else {
          Drupal.behaviors.DonationMultistepHidePaymentFields("bank");
          paymentMethodIsSet = true;
        }
      }
      return validateParams(init_step, recurrenceIsSet, amountIsSet, paymentMethodIsSet);
    },
  };
})(jQuery);
