/* eslint
    func-names: "off",
    object-shorthand: "off",
    no-restricted-globals: "off"
*/
(function ($) {
  Drupal.behaviors.ACLUUpsellModal = {
    attach: function (context) {
      function shouldHideUpsellModal() {
        var currentDonationPaymentMethod = $(
          "#payment-method-used-for-donation"
        ).html();
        return currentDonationPaymentMethod !== "Credit Card";
      }

      var upsellSettings = Drupal.settings["fundraiser-upsell"];
      var upsellModal = $("#modal-content", context);
      var upsellConfirmationModal = $(
        "#modal-content.upsell-thankyou",
        context
      );

      if (upsellSettings !== undefined) {
        if (upsellSettings.loadingText !== undefined) {
          upsellSettings.loadingText = "";
        }
        if (upsellSettings.throbber !== undefined) {
          upsellSettings.throbber = "";
        }
      }

      // By default, Springboard will display the monthly upsell modal even for
      // donations that use a payment method that we haven't set up for monthly
      // donations. Therefore, on the frontend, we want to prevent the modal from
      // popping up when a payment method that's not compatible with monthly donations
      // is used.
      $("#payment-method-used-for-donation", context).once(
        "payment-method-upsell-modal-clear-background",
        function () {
          // This function runs immediately on pageload
          // so we can hide the backdrop ASAP. This prevents
          // a flash of darkness on the confirmation screen
          // when donating with a method that we don't want to
          // show the upsell for.
          if (
            shouldHideUpsellModal() &&
            upsellSettings &&
            upsellSettings.modalOptions
          ) {
            upsellSettings.modalOptions.opacity = 0;
          }
        }
      );
      $("#upsell-modal-content", upsellModal).once(
        "payment-method-upsell-modal-hide",
        function () {
          // We can't run this function when the page loads
          // because the modal isn't there to dismiss yet.
          // Instead, it runs after the modal AJaX code returns.
          if (
            shouldHideUpsellModal() &&
            Drupal.CTools &&
            Drupal.CTools.Modal &&
            Drupal.CTools.Modal.dismiss
          ) {
            Drupal.CTools.Modal.dismiss();
          }
        }
      );

      $("[id^=upsell-modal-subtitle]", upsellModal).once(
        "upsell-move-subtitle",
        function () {
          $('[id^="upsell-modal-title"]', upsellModal).append(this);
        }
      );

      $('label[for^="edit-amount"]', upsellModal).once(
        "upsell-update-amount-label",
        function () {
          $(this).html(
            "Enter your monthly gift &ndash; a suggested amount is filled in:"
          );
        }
      );

      $("#upsell-modal-content", upsellModal).once(
        "upsell-move-content-after-amount",
        function () {
          $(this).insertAfter($("#upsell-modal-amount", upsellModal));
        }
      );

      $("form", upsellModal).once("upsell-wrap-body-content", function () {
        $(
          "#upsell-modal-amount, #upsell-modal-content, [id^='edit-actions']",
          this
        ).wrapAll("<div id='upsell-modal-main-content' />");
      });

      $("#upsell-modal-content", upsellConfirmationModal).once(
        "upsell-wrap-confirmation-body-content",
        function () {
          $(this).wrap("<div id='upsell-modal-main-content' />");
        }
      );

      $(".form-item-amount", upsellModal).once(
        "upsell-wrap-amount-prefix",
        function () {
          $(".field-prefix, [id^='edit-amount']", this).wrapAll(
            "<div id='upsell-modal-amount-wrapper' />"
          );
        }
      );

      $(".alert.error", upsellModal).once("upsell-error", function () {
        var errorContainer = $(this).parent();
        $(".sb-msg-close, h2", errorContainer).remove();
        var errorMessage = $(this).text();
        var amountFieldId = $(
          "[id^='edit-amount'][name='amount']",
          upsellModal
        ).attr("id");
        // tweaks to certain error messages
        var donationValue = $("#" + amountFieldId).val();
        if (donationValue === "") {
          errorMessage = "Please enter a donation amount.";
        } else if (isNaN(parseFloat(donationValue))) {
          errorMessage = "Please enter a valid numerical amount.";
        }
        /* eslint-disable */
        var errorHTML = "";
        errorHTML += "<div class='messages error'>";
        errorHTML += "  <div class='error-intro'>Please check the following fields</div>";
        errorHTML += "  <ul><li>";
        errorHTML += "    <a href='#" + amountFieldId + "' aria-label='" + errorMessage + "'><span>Amount:</span> " + errorMessage + "</a>";
        errorHTML += "  </li></ul>";
        errorHTML += "</div>";
        /* eslint-enable */

        $(this).remove();
        $("#upsell-modal-main-content", errorContainer).prepend(errorHTML);
      });

      $(
        "#modal-content > .close-modal-wrapper .ctools-close-modal",
        context
      ).once("upsell-make-close-button-accessible", function () {
        var closeButton = $(this);
        var closeText = closeButton.text();
        closeButton.attr("aria-label", closeText);
        closeButton.text("");
      });
    },
  };
})(jQuery);
